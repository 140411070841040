  .banner h1 {
    font-size: 2.4vw;
    font-weight: 100;
    letter-spacing: 1px;
    line-height: 2;
      position:absolute;
      margin: 15vw 45vw 20px 10vw;
      text-align: center;
	text-shadow: -3px 3px 0 #121212, 3px 3px 0 #121212, 3px -3px 0 #121212, -3px -3px 0 #121212;
 }

 .banner img {
    border-radius: 0 0 5% 5%;
 }

 .banner {
     margin-top: 100px;
 }
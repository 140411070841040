/************ Career Css ************/
.career {
    padding: 0 0 50px 0;
    position: relative;
  }
  .career-bx {
    background: #151515;
    border-radius: 64px;
    text-align: center;
    padding: 60px 50px;
    margin-top: -60px;
  }

  .career p {
      color: #B8B8B8;
      text-justify: distribute;
  }

  .career h3 {
    font-size: xx-large;
  }  
  .career h5 {
    font-size: large;
  }
  
  .career .vertical-timeline-element-content .vertical-timeline-element-date {
    font-family: "TextEightBit";
  }
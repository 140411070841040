.about img {
    border-radius: 10%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    max-width: 75%;
    max-height: auto;
    min-width: 50%;
    min-height: auto;
    object-fit: cover;
    margin-block: 50px;

}
.about p {
    margin-top: auto;
    margin-bottom: auto;
}
@font-face {
	font-family: HeaderEightBit;
	src: url('./assets/fonts/8-bit-pusab.ttf');
	font-weight: 400;
}

@font-face {
	font-family: TextEightBit;
	src: url('./assets/fonts/pixelmix.ttf');
	font-weight: 1;
}

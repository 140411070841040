/************ Projects Css ************/
.project {
    align-items: center;
    position: relative;
  }

.proj-imgbx {
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  height: auto;
  width: auto;
  align-items:center;
  border: 5px ridge white;
}
.proj-imgbx:hover {
  border: 5px ridge black;
}
  
  .proj-imgbx .proj-imgbx-img {
    padding: 30px;
    height: 100%;
    width: 100%;
  }
  
  .proj-imgbx::before {
    content: "";
    background: white;
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
  }
  .proj-imgbx:hover::before {
    height: 100%;
  }
  .proj-txtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 100%;
    color: black;
    font-family: "TextEightBit", serif;
  
  }
  .proj-imgbx:hover .proj-txtx {
    top: 50%;
    opacity: 1;
  }

  .proj-txtx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 1px;
  }
  .background-image-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
  }
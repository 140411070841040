/************ Footer Css ************/
.footer {
    padding: 200px 0 50px 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    position: relative;
  }

  .footer p {
    text-align: center;
  }
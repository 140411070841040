
  .technologies-slider {
    position: relative;
    text-align: center;
    margin: 75px;
  }
  .technologies-slider .item img {
    width: 200px;
    height: 200px;
    margin: 15px auto;
  }
  
  .technologies li {
    margin: 5px auto
  }  

  .technologies p {
    text-align: justify;
  }
  
  .technologies-slider .item {
    margin: 30px;
    font-family: "TextEightBit", serif;
  }

  .technologies .nav.nav-pills {
    width: 72%;
    margin: 0 auto;
    color: black;
    overflow: hidden;
    font-family: "TextEightBit", serif;
  }
  .technologies .nav.nav-pills .nav-item {
    width: 50%;

  }
  .technologies .nav.nav-pills .nav-link {
    background-color: transparent;
    padding: 17px 0;
    color: white;
    font-size: 17px;
    letter-spacing: 1px;
    font-weight: 500;
    position: relative;
    text-align: center;
    z-index: 0;
    border: 5px ridge white;
  }

  .technologies .nav.nav-pills .nav-link.active {
    color: black;
    border: 5px ridge black;
  }
  .technologies .nav.nav-pills .nav-link::before {
      content: "";
      position: absolute;
      width: 0;
      height: 100%;
      background-color: white;
      text-shadow: black !important;
      top: 0;
      left: 0;
      z-index: -1;
  }

  .technologies .nav.nav-pills .nav-link.active::before {
      width: 100% !important;
      height: 100% !important;
  }
  
/************ Default Css ************/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: black !important;
  color: white !important;
  -webkit-user-select: none; /* Safari */
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+/Edge */
user-select: none; /* Standard */

}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-align: center;
}

h1 {
  font-family: 'HeaderEightBit', serif !important;
}

.section-container {
  margin-inline: 10%;
}

h2, h3, h4, h5, p {
  font-family: 'TextEightBit', serif !important;
  line-height: 3;
}

p {
  font-size: larger;
}

h2 {
  padding-top: 100px;
  padding-bottom: 50px;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: 100%;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

p.success {
	color: green;
}

p.danger {
	color: red;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}